<template>
  <Layout class="MachineLogWrapper">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
        class="log-form"
        size="mini"
      >
        <el-row>
          <el-col>
            <el-form-item prop="user_id">
              <el-input
                v-model.trim="formData.user_id"
                clearable
                placeholder="用户ID"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item prop="current_state">
              <el-select
                v-model="formData.current_state"
                placeholder="当前状态"
                clearable
              >
                <el-option
                  v-for="(item, index) in userStateList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="start_mode">
              <el-select
                v-model="formData.start_mode"
                placeholder="启动模式"
                clearable
              >
                <el-option
                  v-for="(item, index) in gameStartModeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="app_id">
              <el-select
                v-model="formData.app_id"
                placeholder="选择应用"
                clearable
              >
                <el-option
                  v-for="(item, index) in appIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="idc_id">
              <el-select
                v-model="formData.idc_id"
                placeholder="选择机房"
                clearable
              >
                <el-option
                  v-for="(item, index) in fullGameRoomList"
                  :key="index"
                  :label="item.roomName"
                  :value="item.roomId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.game_keyword"
                clearable
                placeholder="游戏ID/游戏名称"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.center_server_code"
                clearable
                placeholder="center_server_code"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.center_pay_code"
                clearable
                placeholder="center_pay_code"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.nation"
                placeholder="地区"
                filterable
                clearable
              >
                <el-option
                  v-for="(item,index) in CountryAbbreviation"
                  :key="index"
                  :label="item.name + '('+ item.value +')'"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              prop="use_time_s"
              label="使用时长"
            >
              <el-row>
                <el-col>
                  <el-input
                    v-model.number="formData.use_time_s"
                    clearable
                    placeholder="小时"
                  />
                  <span style="margin: 0 10px;">至</span>
                  <el-input
                    v-model.number="formData.use_time_e"
                    clearable
                    placeholder="小时"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              prop="daterange"
              label="租借时间"
            >
              <el-date-picker
                v-model="formData.daterange"
                type="datetimerange"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.vip_type"
                placeholder="是否试玩"
                clearable
              >
                <el-option
                  label="全部"
                  value=""
                />
                <el-option
                  label="付费用户"
                  value="1"
                />
                <el-option
                  label="试玩用户"
                  value="2"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.quit_reason"
                placeholder="退出原因"
                clearable
              >
                <el-option
                  label="全部"
                  :value="-1"
                />
                <el-option
                  label="报修"
                  :value="10"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:right>
        <div style="width: 180px">
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
          <el-button
            type="primary"
            @click="handleExport"
          >
            导出
          </el-button>
        </div>
      </template>
    </BHeader>

    <el-table
      v-loading="logListInfo.loading"
      :data="logListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template v-slot="{row}">
          <el-form
            :model="row"
            label-position="right"
            class="table-expand"
            label-width="160px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="启动模式">
                  {{ row.start_mode }}
                </el-form-item>

                <el-form-item label="游戏ID">
                  <span>{{ row.game_id }}</span>
                </el-form-item>
                <el-form-item label="是否使用租号">
                  <span>{{ row.rent_account }}</span>
                </el-form-item>
                <el-form-item label="租用账号名称">
                  <span>{{ row.account_name }}</span>
                </el-form-item>
                <el-form-item label="开始排队时间">
                  <span>{{ row.start_queue_time }}</span>
                </el-form-item>
                <el-form-item label="结束排队时间">
                  <span>{{ row.end_queue_time }}</span>
                </el-form-item>
                <el-form-item label="开始连接时间">
                  <span>{{ row.start_connect_time }}</span>
                </el-form-item>
                <el-form-item label="成功连接时间">
                  <span>{{ row.success_connect_time }}</span>
                </el-form-item>
                <el-form-item label="连接耗时">
                  <span>{{ row.connect_cost }}</span>
                </el-form-item>
                <el-form-item label="注销方式">
                  <span>{{ row.un_register_type }}</span>
                </el-form-item>
                <el-form-item label="用户停留时长(开始排队)">
                  <span>{{ row.app_in_time }}</span>
                </el-form-item>
                <el-form-item label="消费订单ID">
                  <span>{{ row.deducation_bill_id }}</span>
                </el-form-item>
                <el-form-item label="扣除VIP时长">
                  <span>{{ row.deducation_vip_time }}</span>
                </el-form-item>
                <el-form-item label="扣除金币">
                  <span>{{ row.deducation_gold }}</span>
                </el-form-item>
                <el-form-item label="center_server_code">
                  <span>{{ row.center_server_code || '-' }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请资源时间">
                  <span>{{ row.get_resource_time }}</span>
                </el-form-item>
                <el-form-item label="机房信息">
                  <span> {{ row.idc_info }}</span>
                </el-form-item>
                <el-form-item label="机房延迟">
                  <span>{{ row.idc_delay }}</span>
                </el-form-item>
                <el-form-item label="用户IP">
                  <span>{{ row.client_ip }}</span>
                </el-form-item>
                <el-form-item label="用户所在地">
                  <span>{{ row.nation }}</span>
                </el-form-item>
                <el-form-item label="应用信息">
                  <span>{{ row.app_name }}</span>
                </el-form-item>
                <el-form-item label="loading开始时间">
                  <span>{{ row.start_loading_time }}</span>
                </el-form-item>
                <el-form-item label="loading关闭时间">
                  <span>{{ row.end_loading_time }}</span>
                </el-form-item>
                <el-form-item label="loading耗时">
                  <span>{{ row.loading_cost }}</span>
                </el-form-item>
                <el-form-item label="心跳开始记录时间">
                  <span>{{ row.center_heart_beat_start_time }}</span>
                </el-form-item>
                <el-form-item label="心跳中断初始时间">
                  <span>{{ row.center_heart_beat_end_time }}</span>
                </el-form-item>
                <el-form-item label="中台心跳时长">
                  <span>{{ row.center_heart_time }}</span>
                </el-form-item>
                <el-form-item label="预设应用启动状态">
                  <span>{{ row.stream_start_status || '-' }}</span>
                </el-form-item>
                <el-form-item label="最高/最低/平均延迟">
                  <span>{{ row.stream_delays || '-' }}</span>
                </el-form-item>
                <el-form-item label="流桌面内启动程序">
                  <span>{{ row.stream_start_apps || '-' }}</span>
                </el-form-item>
                <el-form-item label="center_pay_code">
                  <span>{{ row.center_pay_code || '-' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="schedule_id"
        label="调度ID"
        width="170"
      />
      <el-table-column
        prop="nation"
        label="地区"
      />
      <el-table-column
        prop="user_id"
        label="用户ID"
        width="170"
      />
      <el-table-column
        label="试玩用户"
        min-width="80"
      >
        <template slot-scope="scope">
          {{ scope.row.vip_type == "9999"?"是":"否" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="game_name"
        label="游戏名称"
        min-width="80"
      />
      <el-table-column
        prop="queue_time"
        label="排队时长"
        min-width="80"
      />
      <el-table-column
        prop="out_queue_type"
        label="出排队方式"
        min-width="80"
      />
      <el-table-column
        prop="idc_ip"
        label="机器IP"
        min-width="80"
      />
      <el-table-column
        prop="current_state"
        label="当前状态"
        min-width="80"
      />
      <el-table-column
        prop="use_time"
        label="机器使用时长"
        min-width="80"
      />
      <el-table-column
        label="操作"
        min-width="120"
      >
        <template v-slot="{row}">
          <el-button
            v-if="row.quit_reason != 9 && row.quit_reason != 10"
            type="danger"
            size="mini"
            style="padding: 5px"
            @click="handleCancellationNoOff(row)"
          >
            注销不重启
          </el-button>
          <el-button
            v-if="(row.quit_reason != 9 && row.quit_reason != 10) && row.current_state == '游戏中' && row.vRMode == 2"
            type="danger"
            size="mini"
            style="padding: 5px"
            @click="domesticRepair(row.schedule_id)"
          >
            报修
          </el-button>
          <el-button
            v-if="row.quit_reason == 10"
            type="danger"
            size="mini"
            style="padding: 5px"
            @click="releaseResources(row.schedule_id)"
          >
            释放资源
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="logListInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.page_size"
      @pagination="queryLogList"
    />
  </Layout>
</template>

<script>
import {
  userStateList,
  userStateMap,
  gameStartModeList,
  gameStartModeMap,
  CountryAbbreviation
} from '@/utils/selectOptions'
import { getEngineRoomInfo, cancellationNoOff, domesticRepair, releaseResources } from '@/api/gameList'
import { cloneDeep } from 'lodash'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { getMachineRecord } from '@/api/log.js'
import { batchQueryParamsForExport } from '@/utils'
import { format } from 'fecha'

const appIdList = [
  { name: 'netboom-ANDROID', value: '20-ANDROID' },
  { name: 'netboom-IOS', value: '20-IOS' },
  { name: 'bifrost-ANDROID', value: '12-ANDROID' },
  { name: 'bifrost-IOS', value: '12-IOS' },
  { name: 'VR-国内', value: '20-VR-2' },
  { name: 'VR-海外', value: '20-VR-3' }
]

const appIdMap = appIdList.reduce((map, item) => {
  map[item.value] = item
  return map
}, {})

export default {
  name: 'MachineLog',
  data () {
    const validateTime = (rule, value, callback) => {
      if (this.formData.use_time_s === undefined && this.formData.use_time_e === undefined) {
        return callback()
      } else if (this.formData.use_time_s === undefined && this.formData.use_time_e !== undefined) {
        callback(new Error('请填写开始时间'))
      } else if (this.formData.use_time_s !== undefined && this.formData.use_time_e === undefined) {
        callback(new Error('请填写结束时间'))
      } else if (isNaN(this.formData.use_time_s) || isNaN(this.formData.use_time_e)) {
        callback(new Error('必须是数字'))
      } else if (this.formData.use_time_s < 0) {
        callback(new Error('开始时间必须大于0'))
      } else if (this.formData.use_time_s >= this.formData.use_time_e) {
        callback(new Error('结束时间必须大于开始时间'))
      } else {
        callback()
      }
    }
    return {
      userStateList,
      userStateMap,
      gameStartModeList,
      gameStartModeMap,
      appIdList,
      appIdMap,
      fullGameRoomList: [],
      fullGameRoomMap: {},
      CountryAbbreviation,
      formData: {
        daterange: this.getYesterdaySection(),
        page: 1,
        page_size: 20
      },
      formCancellation: {
        schedule_id: 0
      },
      rules: {
        use_time_s: [
          { validator: validateTime }
        ]
      },
      logListInfo: {
        list: [],
        loading: false,
        total: 0
      },
      batchUploadTemplate: {
        list: [],
        header: [
          '调度ID',
          '用户ID',
          '游戏名称',
          '排队时长(s)',
          '出排队方式',
          '机器IP',
          '当前状态',
          '机器使用时长(min)',
          '启动模式',
          '游戏ID',

          '是否使用租号',
          '租用账号名称',
          '开始排队时间',
          '结束排队时间',
          '开始连接时间',
          '成功连接时间',
          '连接耗时(s)',
          '注销方式',
          '用户停留时长(开始排队)',
          '消费订单ID',

          '扣除VIP时长(min)',
          '扣除金币',
          '申请资源时间',
          '机房信息',
          '机房延迟(ms)',
          '用户IP',
          '国家(简称)',
          '应用信息',
          'loading开始时间',
          'loading关闭时间',

          'loading耗时(s)',
          '心跳开始记录时间',
          '心跳中断初始时间',
          '中台心跳时长(min)',
          '预设应用启动状态',
          '最高/最低/平均延迟(ms)',
          '流桌面内启动程序',
          'center_server_code',
          'center_pay_code',
          'VR用户(0:非VR,1:无扣费VR,2:国内VR,3国外VR)',

          '最高延迟(ms)',
          '最高最低(ms)',
          '平均延迟(ms)',
          'vip等级(0-普通用户,9999-测试用户)',
          '城市'
        ],
        fields: [
          'schedule_id',
          'user_id',
          'game_name',
          'queue_time',
          'out_queue_type',
          'idc_ip',
          'current_state',
          'use_time',
          'start_mode',
          'game_id',

          'rent_account',
          'account_name',
          'start_queue_time',
          'end_queue_time',
          'start_connect_time',
          'success_connect_time',
          'connect_cost',
          'un_register_type',
          'app_in_time',
          'deducation_bill_id',

          'deducation_vip_time',
          'deducation_gold',
          'get_resource_time',
          'idc_info',
          'idc_delay',
          'client_ip',
          'nation',
          'app_name',
          'start_loading_time',
          'end_loading_time',

          'loading_cost',
          'center_heart_beat_start_time',
          'center_heart_beat_end_time',
          'center_heart_time',
          'stream_start_status',
          'stream_delays',
          'stream_start_apps',
          'center_server_code',
          'center_pay_code',
          'vRMode',

          'delay_max',
          'delay_min',
          'delay_avg',
          'vip_type',
          'region'
        ]
      }
    }
  },
  created () {
    this.queryLogList()
  },
  mounted () {
    this.getEngineRoomInfo()
    this.handleSearch()
  },
  methods: {
    getYesterdaySection (dateFormat = 'YYYY-MM-DD HH:mm:ss') {
      const start = new Date(new Date().toLocaleDateString()).getTime()
      const end = new Date(new Date()).getTime()
      if (dateFormat === 'timestamp') {
        return [start, end]
      }
      return [format(start, dateFormat), format(end, dateFormat)]
    },
    handleSearch () {
      this.formData.page = 1
      this.queryLogList()
    },
    handleCancellationNoOff (row) {
      this.formCancellation.schedule_id = row.schedule_id
      cancellationNoOff(this.formCancellation)
    },
    domesticRepair (scheduleId) {
      domesticRepair({ schedule_id: scheduleId }).then(res => {
        console.log(res)
        if (res.data.success) {
          this.$message({
            message: '报修成功',
            type: 'success'
          })
          this.handleSearch()
        }
      })
    },
    releaseResources (scheduleId) {
      releaseResources({ schedule_id: scheduleId }).then(res => {
        if (res.data.success) {
          this.$message({
            message: '资源释放成功',
            type: 'success'
          })
          this.handleSearch()
        }
      })
    },
    computedIp (connectInfo) {
      const info = JSON.parse(connectInfo || '{}')
      return ((info.data || {}).server_info || {}).innerip || '/'
    },
    computedTime (second) {
      const min = Math.floor(second % (60 * 60))
      const s = ('0' + second % 60).slice(-2)
      const m = ('0' + Math.floor(min / 60)).slice(-2)
      const H = Math.floor(second / (60 * 60))
      const h = H > 9 ? H : '0' + H
      return `${h}:${m}:${s}`
    },
    getEngineRoomInfo () {
      getEngineRoomInfo({})
        .then(res => {
          if (res.code === 200) {
            this.fullGameRoomList = res.data.list || []
            this.fullGameRoomMap = this.fullGameRoomList.reduce((map, item) => {
              map[item.roomId] = item.roomName
              return map
            }, {})
          }
        })
    },
    queryLogList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.logListInfo.loading = true
          const formData = cloneDeep(this.formData)
          /* eslint-disable camelcase */
          const [get_resource_start_time, get_resource_end_time] = formData.daterange || []
          let { use_time_s, use_time_e } = formData
          if (use_time_s) {
            use_time_s = use_time_s * 60 * 60
          }
          if (use_time_e) {
            use_time_e = use_time_e * 60 * 60
          }
          delete formData.daterange
          if (formData.quit_reason === undefined || formData.quit_reason === '') {
            formData.quit_reason = -1
          }
          getMachineRecord({
            ...formData,
            use_time_s,
            use_time_e,
            get_resource_start_time,
            get_resource_end_time
          })
            .then(res => {
              this.logListInfo.list = res.data.list
              this.logListInfo.total = res.data.total
            })
            .finally(() => {
              this.logListInfo.loading = false
            })
        }
      })
    },
    queryLogListForExport () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.logListInfo.loading = true
            const formData = cloneDeep(this.formData)
            /* eslint-disable camelcase */
            const [get_resource_start_time, get_resource_end_time] = formData.daterange || []
            let { use_time_s, use_time_e } = formData
            if (use_time_s) {
              use_time_s = use_time_s * 60 * 60
            }
            if (use_time_e) {
              use_time_e = use_time_e * 60 * 60
            }
            delete formData.daterange

            const paramsList = batchQueryParamsForExport({
              params: {
                ...formData,
                page_size: 100,
                use_time_s,
                use_time_e,
                get_resource_start_time,
                get_resource_end_time
              },
              total: this.logListInfo.total
            })

            Promise.all(paramsList.map(params => getMachineRecord(params)))
              .then(resultList => {
                const result = resultList.reduce((res, item) => {
                  return res.concat(item.data.list)
                }, [])
                resolve(result)
              })
              .finally(() => {
                this.logListInfo.loading = false
              })
          } else {
            resolve(false)
          }
        })
      })
    },
    handleExport () {
      this.queryLogListForExport()
        .then(data => {
          if (data) {
            this.formatToDwonload(data)
            this.downloadExcel()
          }
        })
    },
    formatToDwonload (data) {
      data.map(item => {
        item.queue_time = this.formatHMStoNum(item.queue_time)
        item.use_time = this.formatHMStoNum(item.use_time, 'm')
        item.connect_cost = parseInt(item.connect_cost) || '-'
        item.deducation_vip_time = this.formatHMStoNum(item.deducation_vip_time, 'm')
        item.idc_delay = parseInt(item.idc_delay) || '-'
        item.loading_cost = parseInt(item.loading_cost) || '-'
        item.delay_max = parseInt(item.delay_max) || '-'
        item.delay_min = parseInt(item.delay_min) || '-'
        item.delay_avg = parseInt(item.delay_avg) || '-'
        item.center_heart_time = this.formatHMStoNum(item.center_heart_time, 'm')
        return item
      })
      this.batchUploadTemplate.list = data
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '机器使用日志' + '_' + new Date().toLocaleDateString())
    },
    formatHMStoNum (value, type) {
      var res = 0
      var h; var m; var s = 0
      if (value.indexOf(':') !== -1) {
        const times = value.split(':')
        if (times.length !== 3) {
          return value
        } else {
          h = times[0]
          m = times[1]
          s = times[2]
        }
        var ss
        if (type === 'm') {
          ss = ((h * 60 + m * 1 + s / 60) * 1).toFixed(2)
        } else {
          ss = h * 60 * 60 + m * 60 + parseInt(s) * 1
        }
        res = ss * 1
      } else {
        res = value * 1
      }
      return res || '-'
    }
  }
}
</script>

<style lang="less">
  .MachineLogWrapper{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .log-form{
    .el-input,
    .el-select{
      width: 150px;
    }
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
      width: 270px;
    }
  }
</style>
